const config: WhiteLabelConfig = {
    "class-name": "english",
    "home-title": "Looking for an English Tutor?",
    "home-subtitle": "Search for a Specialist English Tutor",
    "primary-text-colour-class": "english-primary-text-colour",
    "secondary-text-colour-class": "english-secondary-text-colour",
    "primary-colour": "#FF65A0",
    "secondary-colour": "#FF65A0",
    "primary-colour-mui": "#E11770",
    "secondary-colour-mui": "#000000",
    "name-colour": "#EE2F7E",
    "chip-colour": "rgba(59, 118, 204, 1)",
    "footer-content": "English Directory",
    "navbar-link": "Search english tutor",
    "filter-level-label": "English level",
    "filter-level-values": {
        " ": "None",
        "7+/11+": "English ( 7+/11+ )",
        "Primary": "English ( Primary )",
        "Secondary": "English ( Secondary )",
        "GCSE": "English ( GCSE )",
        "A-level": "English ( A-level )",
        "Degree/Adult": "English ( Degree/Adult )"
    },
    "home-level-values": {
        " ": "English ( All )",
        "7+/11+": "English ( 7+/11+ )",
        "Primary": "English ( Primary )",
        "Secondary": "English ( Secondary )",
        "GCSE": "English ( GCSE )",
        "A-level": "English ( A-level )",
        "Degree/Adult": "English ( Degree/Adult )"
    },
    "profile-step-0-title": "What level of english can you teach?",
    "search-result-title": "english",
    "message-colour": "#f5e7ee",
    "profile-step-3-title-placeholder": "E.g. Experienced and committed english tutor. Limit: 50 characters",
    'profile-step-3-about-us-placeholder': "Tell us about yourself e.g. experience as a english tutor, teaching style or approach. Limit: 190 words."
}

export default config;